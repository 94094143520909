export const CONFIG = {
  API_ROOT: 'https://api.tenshianime.ru/api'
}


export default class API {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static request = async (endpoint: string, method: string, body?: any, signal = null) => {
      const headers = new Headers({
          'Content-Type': 'application/json',
          'X-Hostname': 'tenshi-backend.onrender.com'
      })
      const options: RequestInit = {
          method,
          credentials: 'include',
          headers
      }

      if (body) {
          options.body = JSON.stringify(body)
      }

      if (signal) {
          options.signal = signal
      }

      return fetch(`${CONFIG.API_ROOT}/${endpoint}`, options)
          .then(response => {
              return getJSON(response)
          })
          .catch(err => {
              throw err
          })
  }

}

function getJSON(response: Response) {
  return response
      .json()
      .then(obj => {
          return obj
      })
      .catch(() => {
          return null
      })
}
