import {
  Button,
  Navbar,
  NavbarContent,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Link as LinkNextUI,
  Tooltip
} from '@nextui-org/react'
import React from 'react'

import { useAppSelector } from '../../app/hooks'
import { Link } from 'react-router-dom'
import DarkModeSwitch from './DarkModeSwitch'
import { selectUser } from '../../features/user/userSlice'
import { UserDropdown } from '../UserDropdown/UserDropdown'
import useDarkMode from 'use-dark-mode'

import TomiIcon from '../../assets/icons/tomi.svg?react'

interface Props {
  children: React.ReactNode
}

export const Header = ({ children }: Props) => {
  const darkMode = useDarkMode(false)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const user = useAppSelector(selectUser)

  return (
    <div className="relative flex w-full flex-col overflow-y-auto overflow-x-hidden">
      <Navbar
        isBordered
        className="w-full"
        classNames={{
          wrapper: 'w-full max-w-full'
        }}
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
      >
        <NavbarContent className="md:hidden">
          <NavbarMenuToggle
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          />
        </NavbarContent>
        <NavbarContent justify="start"></NavbarContent>
        <NavbarContent
          justify="end"
          className="w-fit data-[justify=end]:flex-grow-0"
        >
          {user && (
            <Tooltip
              classNames={{
                content: ['text-white bg-[#eb5628]']
              }}
              content='Томи - это валюта проекта'
              placement="bottom"
            >
              <div className="flex gap-2 items-center">
                <TomiIcon className="w-[24px] h-[24px]" />
                <span className="font-bold">{user.profile.points}</span>
              </div>
            </Tooltip>
          )}
          <DarkModeSwitch
            onChange={darkMode.toggle}
            isSelected={darkMode.value}
          />
          {user ? (
            <UserDropdown
              email={user.email}
              username={user.username}
              avatar={user?.profile?.avatar || ''}
              level={user.profile.level}
            />
          ) : (
            <Link to="/login">
              <Button
                className="border-[#eb5628] text-[#eb5628] hover:!bg-[#eb5628] hover:text-white"
                variant="ghost"
              >
                Авторизоваться
              </Button>
            </Link>
          )}
        </NavbarContent>
        <NavbarMenu>
          <NavbarMenuItem>
            <LinkNextUI className="w-full" href="/anime/catalog" size="lg">
              Поиск
            </LinkNextUI>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link className="w-full" to="/">
              Главная
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link className="w-full" to="/anime/catalog">
              Каталог
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link className="w-full" to="/anime/schedule">
              Расписание релизов
            </Link>
          </NavbarMenuItem>
          {user && (
            <NavbarMenuItem>
              <LinkNextUI className="w-full" href="/profile/lists" size="lg">
                Списки
              </LinkNextUI>
            </NavbarMenuItem>
          )}
        </NavbarMenu>
      </Navbar>
      {children}
    </div>
  )
}
